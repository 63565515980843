import React from 'react'

class All extends React.Component {
  render () {
    return (
      <>
        {/* <!-- Contact Area Start --> */}
        <section className='contact-our-area section-padding-100-0' id='contact'>
          <div className='container'>
            <div className='row'>
              {/* <!-- Heading --> */}
              <div className='col-12'>
                <div className='section-heading-2 text-center wow fadeInUp' data-wow-delay='300ms'>
                  <p>wanna win?</p>
                  <h4>ENROLL Now</h4>
                </div>
              </div>
            </div>

            <div className='row justify-content-between'>

              <div className='col-12 col-sm-8'>
                {/* <!-- Contact Form --> */}
                <div className='contact_from_area mb-100 clearfix wow fadeInUp' data-wow-delay='300ms'>
                  <div className='contact_form'>
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdbh4-QJGEQiSpUnScoNeypgNapj7LhjzsHOjP9BTxaI0Hk7Q/viewform?embedded=true" width="1100" height="1550" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    {/* <form action='sendEmail.php' method='post' id='main_contact_form'>
                      <div className='contact_input_area'>
                        <div id='success_fail_info' />
                        <div className='row'>
                          <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                              <input type='text' className='form-control mb-30' name='contactName' id='contactName' placeholder='Your Name' />
                            </div>
                          </div>
                          <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                              <input type='email' className='form-control mb-30' name='contactEmail' id='contactEmail' placeholder='E-mail' />
                            </div>
                          </div>
                          <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                              <input type='text' className='form-control mb-30' name='contactSubject' id='contactSubject' placeholder='Your Number' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='form-group'>
                              <textarea name='message' className='form-control mb-30' id='contactMessage' cols='30' rows='6' placeholder='Your Message *' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <button type='submit' className='btn confer-btn'>Send Message <i className='zmdi zmdi-long-arrow-right' /></button>
                          </div>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default All

import React from 'react'

import Layout from '../components/layout'
import Intro from '../components/index/hackathon'
import About from '../components/index/aboutHackathon'
import Gallery from '../components/index/contests';
import Contact from '../components/index/hackathonContact';

export default ({ data }) => {
  return (
    <Layout>
      <Intro />
      <main id='main' >
        <About />
        <Gallery />
        <Contact />
      </main>
    </Layout>
  )
}
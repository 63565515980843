import React from 'react'

class About extends React.Component {
  render () {
    let aboutText1, aboutText2, aboutPoints, aboutImage, aboutTopic
    if (this.props.data === undefined) {
      aboutTopic = 'About hackathon'
      aboutText1 =  'ELECTRONICS HACKATHON'
      aboutText2 =  'RoboChef\'s Electronics Hackathon is here to identify smart minds from various Engineering institutions. Cash Prize is available for the top winning teams. This event helps Engineering students in identifying their career path and will help them to shine in their future.'
      aboutPoints = [
        'RoboChef - Robotic Kitchen India',
        'RoboChef - Robotic Kitchen India',
        'RoboChef - Robotic Kitchen India'
      ]
      aboutImage = '/img/contests/12.gif'
    } else {
      aboutTopic = this.props.data.aboutTopic
      aboutText1 = this.props.data.aboutText1
      aboutText2 = this.props.data.aboutText2
      aboutPoints = this.props.data.aboutPoints
      aboutImage = this.props.data.aboutImage
    }

    return (
      <section className='about-us-countdown-area section-padding-100-0' id='about'>
        <div className='container'>
          <div className='row align-items-center'>
            {/* <!-- About Content --> */}
            <div className='col-12 col-md-6'>
              <div className='about-content-text mb-80'>
                <h6 className='wow fadeInUp' data-wow-delay='300ms'>{aboutTopic}</h6>
                <h5 className='wow fadeInUp' style={{color: 'yellow'}} data-wow-delay='300ms'>{aboutText1}</h5>
                <p className='wow fadeInUp' data-wow-delay='300ms'>{aboutText2}</p>
                {/* <a href='#' className='btn confer-btn mt-50 wow fadeInUp' data-wow-delay='300ms'>Interested <i className='zmdi zmdi-long-arrow-right' /></a> */}
              </div>
            </div>

            {/* <!-- About Thumb --> */}
            <div className='col-12 col-md-6'>
              <div className='about-thumb mb-80 wow fadeInUp' data-wow-delay='300ms'>
                <img src={aboutImage} alt='' />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Counter Up Area --> */}
        <div className='countdown-up-area'>
          <div className='container'>
            <div className='row align-items-center'>
              {/* <div className='col-12 col-md-3'>
                <div className='countdown-content-text mb-100 wow fadeInUp' data-wow-delay='300ms'>
                  <h6>Conference Date</h6>
                  <h4>Count Every Second Until the Event</h4>
                </div>
              </div> */}

              <div className='col-12 col-md-9'>
                <div className='countdown-timer mb-100 wow fadeInUp' data-wow-delay='300ms'>
                  {/* <div id='clock' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About

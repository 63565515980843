import React from 'react'
import Helmet from 'react-helmet'

class Welcome extends React.Component {
  render () {
    let title,finalT, slug='', heroText, heroImage, aboutText, aboutText2, url,cities=[],citySeo,aweKey=[],locations=[],locSeo,services_btn=''
    cities = ['Chennai','India']
    locations = ['Velachery', 'Adyar']
    aweKey = ['Fully Automated ', 'Automated Kitchen', 'RoboChef','Cloud Kitchen', 'Connected Kitchen' ,'Cooking Robot Stir', 'Mechanical Chef',  'Robotic Chef ', 'Robotic Restaurant', 'Automated Restaurant','cooking robot arms', 'Chef bot', 'Robot that cooks','food robot']
    if (this.props.data === undefined) {
      title = 'Electronics hackathon'
      heroText = 'World\'s First Fully Automated Robotic Kitchen'
      heroImage = 'img/bg-img/bg-home-2.jpg'
      slug = ''
      citySeo = ""
      services_btn = 'Our Services'
      locSeo = ""
      for (let i = 0; i < 2; i++) {
        citySeo = citySeo + "First Robotic Kitchen in " + cities[i] + ". " + "Fully Automated Restaurent in " + cities[i] + ". "
      }
      for (let i = 0; i < 2; i++) {
        locSeo = locSeo + "First Robotic Kitchen in " + locations[i] + ". " + "Fully Automated Restaurent in " + locations[i] + ". "
      }
      for (let j = 0; j < 12; j++) {
      for (let i = 0; i < 2; i++) {
        citySeo = citySeo + aweKey[j] + " in " + cities[i] + ". "
      }
      slug = slug + aweKey[j] + " " 
    }
    aboutText2 = 'The RoboChef - The Robotic Kitchen aka the Automated cooking-bot is completely an interchangeable option in this buzz world. This machine cooks/serves a delicious meal, with the same consistent taste. This even comes along with a customizable meal with varied choices. This machine operates with high speed and accuracy without any distortion in aroma & savor.'
    aboutText = citySeo + 'Completely operates through IoT and Machine Learning. India made Robot, that can cook on it\'s own.' + locSeo
    aboutText2 = 'One of the Best Indian Robotics Company invites you to participate in the Electronics hackathon. Win Cash prizes, Internships and much more.'
    }
    finalT = title + " India | Robotic Restaurant Kitchen in Chennai"
    url = 'https://robochef.co/'
    return (
      <section className='welcome-area'>
        <Helmet>
          <title>{finalT}</title>
          <meta name='description' content={aboutText2} />
          <meta name='image' content={heroImage} />
          <meta name='author' content='Admatic Solutions' />

          <link rel='canonical' href={url} />

          <meta property='og:locale' content='en_US' />
          <meta property='og:type' content='business.business' />
          <meta property='og:title' content={title} />
          <meta property='og:site_name' content='https://robochef.co | Automated Cooking Restaurant'/>
          <meta property='og:description' content={aboutText2} />
          <meta property='og:url' content={url} />
          <meta property='og:image' content='http://robotickitchen.in/images/robochef.webp' />

          <meta property='business:contact_data:street_address' content='#67, 2c, Devadaya, Gandhinagar, 1st Main Road' />
          <meta property='business:contact_data:locality' content='Chennai' />
          <meta property='business:contact_data:region' content='TamilNadu' />
          <meta property='business:contact_data:postal_code' content='600020' />
          <meta property='business:contact_data:country_name' content='India' />
        </Helmet>
        <div className='welcome-slides owl-carousel'>
          {/* <!-- Single Slide --> */}
          <div className='single-welcome-slide bg-img bg-overlay jarallax' style={{ backgroundImage: 'url(img/contests/4.png)' }}>
            <div className='container h-100'>
              <div className='row h-100 align-items-center'>
                {/* <!-- Welcome Text --> */}
                <div className='col-12'>
                  <div className='welcome-text text-right'>
                    <h2 data-animation='fadeInUp' data-delay='300ms'>{title}</h2>
                    
                    <h6 data-animation='fadeInUp' data-delay='500ms'>
                      Chennai</h6>
                    <div className='hero-btn-group' data-animation='fadeInUp' data-delay='700ms'>
                      <a href='/hackathon#about' className='btn confer-btn'>More Information <i className='zmdi zmdi-long-arrow-right' /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Single Slide --> */}
          <div className='single-welcome-slide bg-img bg-overlay jarallax' style={{ backgroundImage: `url(${heroImage})` }}>
            <div className='container h-100'>
              <div className='row h-100 align-items-center'>
                {/* <!-- Welcome Text --> */}
                <div className='col-12'>
                  <div className='welcome-text-two text-center'>
                    <h5 data-animation='fadeInUp' data-delay='100ms'>{heroText}</h5>
                    <h2 data-animation='fadeInUp' data-delay='300ms'>{title}</h2>
                    {/* <!-- Event Meta --> */}
                    <div className='hero-btn-group' data-animation='fadeInUp' data-delay='700ms'>
                      <a href='/hackathon#services' id='scroll-services' className='btn confer-btn m-2'>View Contests <i className='zmdi zmdi-long-arrow-right' /></a>
                      {/* <a href='#' className='btn confer-btn m-2'>Get Tickets <i className='zmdi zmdi-long-arrow-right' /></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Scroll Icon --> */}
        <div className='icon-scroll' id='scrollDown' />
      </section>
    )
  }
}

export default Welcome
